import React from 'react'
import {
  AlternativeHero,
  Breadcrumbs,
  Page,
  EuProjectDescription,
} from '@components'

const meta = {
  title: 'Certifikacijom proizvoda poduzeća FSB d.o.o. do tržišta',
  description: 'Poduzeće Fsb d.o.o. je dana 19.06.2020. godine potpisalo Ugovor o dodjeli bespovratnih sredstava za projekte koji se financiraju iz Europskih strukturnih i investicijskih fondova.',
}

const EUPage = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/projekt-eu/', name: 'Certifikacijom proizvoda poduzeća FSB d.o.o. do tržišta'},
      ]}
    />
    <AlternativeHero
      title="EU Projekt: Certifikacijom proizvoda poduzeća FSB d.o.o. do tržišta"
      subtitle=""
      description="Poduzeće Fsb d.o.o. je dana 19.06.2020. godine potpisalo Ugovor o dodjeli bespovratnih sredstava za projekte koji se financiraju iz
      Europskih strukturnih i investicijskih fondova u financijskom razdoblju 2014.-2020. te je započelo s provedbom projekta
      „Certifikacijom proizvoda poduzeća FSB d.o.o. do tržišta“. Projekt je sufinanciran sredstvima iz Europskog fonda za regionalni
      razvoj, Operativni program Konkurentnost i kohezija. Kao posljedica provedbe projekta „Certifikacijom proizvoda poduzeća FSB
      d.o.o. do tržišta“ poduzeća Fsb d.o.o. bit će očuvana radna mjesta, povećanje konkurentnosti te rast prihoda od prodaje i izvoza
      poduzeća."
    />
    <EuProjectDescription />
  </Page>
)

export default EUPage